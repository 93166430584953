import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import Member from "../components/member"

export const query = graphql`
  query MyQuery {
    groupLeader: contentfulMembers(
      isCurrentMember: { eq: true }
      group: { name: { eq: "Group Leader" } }
    ) {
      id
      fullName
      jobTitle
      email
      isCurrentMember
      curriculum {
        json
      }
      picture {
        fluid {
          src
        }
      }
    }
    researchAssistant: contentfulMembers(
      isCurrentMember: { eq: true }
      group: { name: { eq: "Research Assistant" } }
    ) {
      id
      fullName
      jobTitle
      email
      isCurrentMember
      curriculum {
        json
      }
      picture {
        fluid {
          src
        }
      }
    }
    postdoctoralResearchers: allContentfulMembers(
      filter: {
        isCurrentMember: { eq: true }
        group: { name: { eq: "Postdoctoral Researcher" } }
      }
    ) {
      nodes {
        id
        fullName
        jobTitle
        email
        isCurrentMember
        curriculum {
          json
        }
        picture {
          fluid {
            src
          }
        }
      }
    }
    phDStudents: allContentfulMembers(
      filter: {
        isCurrentMember: { eq: true }
        group: { name: { eq: "PhD student" } }
      }
    ) {
      nodes {
        id
        fullName
        jobTitle
        email
        isCurrentMember
        curriculum {
          json
        }
        picture {
          fluid {
            src
          }
        }
      }
    }
  }
`

const CurrentMembers = ({ data }) => {
  const {
    groupLeader,
    researchAssistant,
    postdoctoralResearchers,
    phDStudents,
  } = data
  return (
    <Layout>
      <div className="container">
        <div className="section">
          <h1 className="title is-1 mb-6">Current members</h1>
          <div className="columns is-multiline">
            <Member member={groupLeader} />
            <Member member={researchAssistant} />
            {postdoctoralResearchers.nodes.map(member => (
              <Member member={member} />
            ))}
            {phDStudents.nodes.map(member => (
              <Member member={member} />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CurrentMembers
