import React from "react"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

import { capitalize } from "../utils"

const Member = ({ member }) => (
  <div
    key={member.id}
    className="column is-one-third-desktop is-half-tablet is-full-mobile"
  >
    <div className="card mx-2">
      <div className="card-image">
        <figure className="image is-4by3">
          <img
            className="card-image__member"
            src={member.picture.fluid.src}
            alt="lab member"
          />
        </figure>
      </div>
      <div className="card-content">
        <div className="media">
          <div className="media-content">
            <p className="title is-3 pb-1">{capitalize(member.fullName)}</p>
            <p className="subtitle is-5 mb-1">{member.jobTitle}</p>
            <a href={`mailto:${member.email}`} className="subtitle is-6">
              {member.email}
            </a>
          </div>
        </div>
        <div className="content has-text-justified">
          {documentToReactComponents(member.curriculum?.json)}
        </div>
      </div>
    </div>
  </div>
)

export default Member
